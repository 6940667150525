import React, { useEffect, useState } from "react";
import "./guestlistWidget.css";
import { useDispatch, useSelector } from "react-redux";
import { GET_EVENT_GUESTLIST } from "../../../redux/action/eventAction";
import ProfilePicture from "../../user/profilePicture/profilePicture";

function GuestlistWidget() {
  const dispatch = useDispatch();
  const currentEvent = useSelector((state) => state.eventReducer.currentEvent);
  const eventGuestlist = useSelector((state) => state.eventReducer.eventGuestlist);
  const [expandGuestlist, setExpandGuestlist] = useState(false);

  useEffect(() => {
    if (currentEvent && currentEvent.event_id) {
      dispatch({ type: GET_EVENT_GUESTLIST, payload: currentEvent.event_id });
    }
  }, [currentEvent]);

  return (
    <div className={"guestListView"}>
      <div className="guestListTitle semibold">Guestlist</div>
      <div className="guestListContent">
        <div className="guestListPreview">
          {eventGuestlist.slice(0, 4).map((guest, index) => {
            return (
              <div key={index}>
                <ProfilePicture className={"guestlistPhoto"} userId={guest.user_id} />
                <div className="guestlistText">{guest.user_first_name}</div>
              </div>
            );
          })}
        </div>
        <div className="goingSection">
          {eventGuestlist.length > 4 && (
            <div
              className="clickable"
              onClick={() => {
                setExpandGuestlist(true);
              }}
            >
              <div className="goingBigText">+{eventGuestlist.length - 4}</div>
              <div className="guestlistText">{`${eventGuestlist.length - 4 > 1 ? "others" : "other"} going`}</div>
            </div>
          )}
          {eventGuestlist.length <= 4 && (
            <div>
              <div className="goingBigText">{eventGuestlist.length}</div>
              <div className="guestlistText">going</div>
            </div>
          )}
        </div>
      </div>
      {expandGuestlist ? (
        <div
          className="guestlistExpandView"
          onClick={(e) => {
            e.stopPropagation();
            setExpandGuestlist(false);
          }}
        >
          <div className="guestlistExpand invisiScroll">
            <div className="guestlistRows">
              {eventGuestlist.map((guest, index) => {
                return (
                  <div key={index}>
                    <ProfilePicture className={"guestlistPhoto"} userId={guest.user_id} />
                    <div className="guestlistText">{guest.user_first_name}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default GuestlistWidget;
