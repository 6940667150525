import { useEffect, useState } from "react";
import React from "react";
import { Image } from "antd";
import "./horizontalEventCard.css";
import { S3Bucket } from "../../../Constants";
import HostCardView from "../../hostCardView/hostCardView";
import RightArrow from "../../../assets/RightArrow.png";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

function HorizontalEventCard({ event }) {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [eventTimeString, setEventTimeString] = useState("");

  useEffect(() => {
    const eventDate = new Date(event.event_start_time);
    const eventTime = eventDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    setEventTimeString(eventTime);
  }, [event.event_start_time]);

  return (
    <div
      className="horizontalCard"
      onClick={() => {
        navigate(`/details/${event.event_id}`);
      }}
    >
      {isDesktop ? (
        <div className="horizontalCardImageHolder">
          <Image
            className="horizontalCardImage"
            width={150}
            height={"100%"}
            preview={false}
            src={event.event_picture.includes("http") ? event.event_picture : `${S3Bucket}event/${event.event_id}/${event.event_id}.jpg`}
            fallback="https://host-sonder.s3.us-west-2.amazonaws.com/asset/SquareLogo.png"
          />
        </div>
      ) : null}
      <div className="horizontalCardEventContent">
        <div className="event-description">
          <HostCardView event={event} />
          <div className="horizontalCardTldr">{event.event_tldr}</div>
          <div className="event-date">
            <span className="semibold">@</span> {eventTimeString}
          </div>
        </div>
        <div className="event-tags">
          <div className="event-tag">
            {event.event_type === "crowdfund"
              ? `${(event.fundedAmount / (event.event_fund_target ?? 1)) * 100}% Funded`
              : event.event_type === "paid"
                ? "Paid Event"
                : "Free Event"}
          </div>
          <div className="event-tag">{event.event_location ? event.event_location : `${event.event_location_noun} in ${event.event_location_place}`}</div>
        </div>
      </div>
      <div className="open">
        <img className="eventArrowImage" src={RightArrow}></img>
      </div>
    </div>
  );
}

export default HorizontalEventCard;
