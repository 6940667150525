import React, { useEffect, useState } from "react";
import "./eventDetailTagSection.css";
import EventDetailTags from "../eventDetailTags/eventDetailTags";
import ConfirmationDate from "../../../assets/ConfirmationDate.png";
import ConfirmationTime from "../../../assets/ConfirmationTime.png";
import ConfirmationLocation from "../../../assets/ConfirmationLocation.png";

function EventDetailTagSection({ event }) {
  const [roughEventDateString, setRoughEventDateString] = useState("");

  useEffect(() => {
    if (!event || !event.event_date_tags) {
      return;
    }

    let tmpEventDateString = "";

    if (event.event_date_tags.includes("Weekend") && !event.event_date_tags.includes("Weekday")) {
      tmpEventDateString += "A weekend in ";
    } else if (event.event_date_tags.includes("Weekday") && !event.event_date_tags.includes("Weekend")) {
      tmpEventDateString += "A weekday in ";
    }

    const monthSeasonList = event.event_date_tags.filter((tag) => {
      return !(tag.includes("Weekend") || tag.includes("Weekday"));
    });

    tmpEventDateString += monthSeasonList.join(", ");
    tmpEventDateString = tmpEventDateString.replace(/ 2024/g, "");

    tmpEventDateString += " 2024";

    setRoughEventDateString(tmpEventDateString);
    if (event.event_date_tags.filter((tag) => !tag.includes("")).length <= 0) {
      setRoughEventDateString("TBD");
    }
  }, [event]);

  if (Object.keys(event).length === 0) {
    return null;
  }

  const timestamp = event.event_start_timestamp;
  const formattedDate = new Date(timestamp).toLocaleDateString("en-US", { weekday: "long", month: "long", day: "numeric" });
  const formattedTime = new Date(timestamp).toLocaleTimeString("en-US", { hour: "numeric", minute: "2-digit", timeZoneName: "short" });

  return (
    <>
      <div className="confirmationTags">
        {(event.exact_date || event.exact_date == 1) && <EventDetailTags image={ConfirmationDate} text={formattedDate} />}
        {(event.exact_date || event.exact_date == 1) && event.event_start_time && <EventDetailTags image={ConfirmationTime} text={formattedTime} />}
        {!event.exact_date && <EventDetailTags image={ConfirmationDate} text={roughEventDateString} />}
      </div>
      <div className="confirmationTags">
        {(event.exact_location || event.exact_location == 1) && (
          <EventDetailTags
            image={ConfirmationLocation}
            text={event.event_location}
            action={() => {
              window.open(`https://www.google.com/maps/search/?api=1&query=${event.event_location}`, "_blank");
            }}
          />
        )}
        {!event.exact_location && (
          <EventDetailTags
            image={ConfirmationLocation}
            text={event.event_location === "TBD" ? "TBD" : `${event.event_location_noun} in ${event.event_location_place}`}
          />
        )}
      </div>
    </>
  );
}

export default EventDetailTagSection;
