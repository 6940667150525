import React from "react";
import "./editWidgetOverlay.css";
import { useDispatch, useSelector } from "react-redux";
import { SET_EDIT_WIDGET_CONTENT, SET_EDIT_WIDGET_STEP, TOGGLE_EDIT_WIDGET } from "../../../redux/action/dashboardAction";
import ArrowIconBlack from "../../../assets/ArrowIconBlack.png";
import Close from "../../../assets/Close.png";
import EditPollWidget from "../../widgets/pollWidget/editPollWidget";
import EditPromptWidget from "../../widgets/promptWidget/editPromptWidget";
import EditPhotoWidget from "../../widgets/photoWidget/editPhotoWidget";
import EditBringWidget from "../../widgets/bringWidget/editBringWidget";

function EditWidgetOverlay() {
  const dispatch = useDispatch();
  const editWidgetStep = useSelector((state) => state.dashboardReducer.editWidgetStep);

  const availableWidgets = [
    {
      type: "poll",
      name: "Poll",
      desc: "Ask questions and receive a breakdown of responses.",
    },
    {
      type: "prompt",
      name: "Prompt",
      desc: "Ask questions and get written responses.",
    },
    {
      type: "photo",
      name: "Gallery",
      desc: "Show off pics of your event.",
    },
    {
      type: "bring",
      name: "Who's Bringing What",
      desc: "Let guests know what others are bringing.",
    },
  ];

  function selectNewWidget() {
    return (
      <div
        className="editWidgetModal"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="editWidgetContent">
          <div className="editWidgetTitleRow">
            <div className="editWidgetTitle">Keep your ticket holders up to date</div>
            <img
              className="editWidgetCloseImg"
              src={Close}
              alt="Close"
              onClick={() => {
                dispatch({ type: TOGGLE_EDIT_WIDGET });
                dispatch({ type: SET_EDIT_WIDGET_CONTENT, payload: {} });
              }}
            />
          </div>
          <div className="editWidgetDesc">Add components to your dashboard to communicate with your community.</div>
          <div className="editWidgetOptions">
            {availableWidgets.map((widget) => {
              return (
                <div
                  className="widgetOption"
                  key={widget.type}
                  onClick={() => {
                    dispatch({ type: SET_EDIT_WIDGET_STEP, payload: widget.type });
                  }}
                >
                  <div className="widgetOptionTitle">{widget.name}</div>
                  <div className="widgetOptionDesc">{widget.desc}</div>
                  <div className="widgetOptionButton">
                    <div className="widgetOptionButtonText">Add {widget.name}</div>
                    <img className="widgetOptionButtonImg" src={ArrowIconBlack} alt="widgetOptionButtonImg" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="editWidgetBackground"
      onClick={() => {
        dispatch({ type: TOGGLE_EDIT_WIDGET });
        dispatch({ type: SET_EDIT_WIDGET_CONTENT, payload: {} });
      }}
    >
      {editWidgetStep === "new" && selectNewWidget()}
      {editWidgetStep === "poll" && <EditPollWidget />}
      {editWidgetStep === "prompt" && <EditPromptWidget />}
      {editWidgetStep === "photo" && <EditPhotoWidget />}
      {editWidgetStep === "bring" && <EditBringWidget />}
    </div>
  );
}

export default EditWidgetOverlay;
