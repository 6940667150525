import React from "react";
import "./eventImage.css";
import { useSelector } from "react-redux";
import { S3Bucket } from "../../../Constants";
import { Image } from "antd";

function EventImage() {
  const event = useSelector((state) => state.eventReducer.currentEvent);

  if (event === null || event === undefined || event.event_id === undefined) {
    return null;
  }

  return (
    <div className="eventImageSpace">
      <Image
        className="eventImage"
        width={"100%"}
        height={"100%"}
        preview={false}
        src={event.event_picture.includes("http") ? event.event_picture : `${S3Bucket}event/${event.event_id}/${event.event_id}.jpg`}
        fallback="https://host-sonder.s3.us-west-2.amazonaws.com/asset/LongLogo.png"
      />
    </div>
  );
}

export default EventImage;
