import React, { useContext, useEffect, useState } from "react";
import "./responseManagement.css";
import { GET_EVENT, GET_EVENT_TIERS, GET_EVENT_TRANSACTIONS } from "../../../redux/action/eventAction";
import { useDispatch, useSelector } from "react-redux";
// import FundingProgressWidget from "../../../components/widgets/fundingProgressWidget/fundingProgressWidget";
import PollWidget from "../../../components/widgets/pollWidget/pollWidget";
import { GET_DASHBOARD } from "../../../redux/action/dashboardAction";
import NewWidget from "../../widgets/newWidget/newWidget";
import ScanWidget from "../../widgets/scanWidget/scanWidget";
import LiveDashboardToggleWidget from "../../widgets/liveDashboardToggleWidget/liveDashboardToggleWidget";
import PromptWidget from "../../widgets/promptWidget/promptWidget";
import PhotoWidget from "../../widgets/photoWidget/photoWidget";
import GridItem, { DragItem, GridContext } from "./Grid";
import BringWidget from "../../widgets/bringWidget/bringWidget";
import TicketWidget from "../../widgets/ticketWidget/ticketWidget";
import InvoiceWidget from "../../widgets/invoiceWidget/invoiceWidget";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

function ResponseManagement({ eventId }) {
  const dispatch = useDispatch();
  const [nextIndex, setNextIndex] = useState(0);
  const currentEvent = useSelector((state) => state.eventReducer.currentEvent);
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const dashboardEdit = useSelector((state) => state.dashboardReducer.dashboardEditMode);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const { currentDashboard, moveItem } = useContext(GridContext);
  const [dashL, setDashL] = useState([]);
  const [dashR, setDashR] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [refresh2, setRefresh2] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    setDashL(currentDashboard.filter((_, index) => index % 2 === 0));
    setDashR(currentDashboard.filter((_, index) => index % 2 !== 0));
    setRefresh(!refresh);
  }, [currentDashboard]);

  useEffect(() => {
    if (currentEvent.user_id && currentUser.user_id === currentEvent.user_id) {
      setIsOwner(true);
    }
    // Harin's Power User
    if (currentUser.user_id === "Pf1BEZ8sFDfCxFzf8ULVxUOo39y1") {
      setIsOwner(true);
    }
  }, [currentUser, currentEvent]);

  useEffect(() => {
    if (dashL.length === 0 || dashR.length === 0) return;
    const dashLLength = dashL.reduce((acc, data) => acc + (data.component_size === "small" ? 1 : 2), 0);
    const dashRLength = dashR.reduce((acc, data) => acc + (data.component_size === "small" ? 1 : 2), 0);
    const tempDashL = dashL;
    const tempDashR = dashR;
    const rCount = tempDashR.length;
    const lCount = tempDashL.length;

    if (dashLLength - dashRLength >= 2) {
      if (lCount - rCount >= 1) {
        tempDashR.push(tempDashL.pop());
      }
    } else if (dashRLength - dashLLength >= 2) {
      if (rCount - lCount >= 1) {
        tempDashL.push(tempDashR.pop());
      }
    }
    setDashL(tempDashL);
    setDashR(tempDashR);
    setRefresh2(!refresh2);
  }, [refresh]);

  useEffect(() => {
    dispatch({ type: GET_EVENT, payload: eventId });
    dispatch({ type: GET_EVENT_TIERS, payload: eventId });
    dispatch({ type: GET_EVENT_TRANSACTIONS, payload: eventId });
    dispatch({ type: GET_DASHBOARD, payload: eventId });
  }, [dispatch, eventId]);

  useEffect(() => {
    let totalGrids = 0;
    currentDashboard.forEach((data) => {
      totalGrids += data.component_size === "small" ? 1 : 2;
    });
    setNextIndex(totalGrids);
  }, [currentDashboard]);

  // Guest Mobile & Desktop View
  if (!isOwner) {
    if (currentDashboard.length === 0) {
      return; // Don't show anything if there are no widgets
    }
    if (isDesktop) {
      // Guest Desktop View
      return (
        <div className="responseDashboard">
          <div className="widgetHolder grid">
            {/* <div className="widget2">
              <FundingProgressWidget />
            </div> */}
            {(refresh2 || !refresh2) &&
              dashL.map((data) => {
                let classString = data.component_size === "small" ? "widget2" : "widget2 widget2y";
                if (data.type === "photo") {
                  classString += " widgetPadding0";
                }
                return (
                  <div className={classString} key={data.type}>
                    {data.type === "poll" ? (
                      <PollWidget live={true} data={data} />
                    ) : data.type === "prompt" ? (
                      <PromptWidget live={true} data={data} />
                    ) : data.type === "photo" ? (
                      <PhotoWidget live={true} data={data} />
                    ) : data.type === "bring" ? (
                      <BringWidget live={!dashboardEdit} data={data} />
                    ) : null}
                  </div>
                );
              })}
          </div>

          <div className="widgetHolder grid">
            {currentEvent?.event_type !== "free" && (
              <div className="widget1">
                <TicketWidget />
              </div>
            )}
            {currentEvent?.event_type !== "free" && (
              <div className="widget1">
                <InvoiceWidget />
              </div>
            )}
            {dashR.map((data) => {
              let classString = data.component_size === "small" ? "widget2" : "widget2 widget2y";
              if (data.type === "photo") {
                classString += " widgetPadding0";
              }
              return (
                <div className={classString} key={data.type}>
                  {data.type === "poll" ? (
                    <PollWidget live={true} data={data} />
                  ) : data.type === "prompt" ? (
                    <PromptWidget live={true} data={data} />
                  ) : data.type === "photo" ? (
                    <PhotoWidget live={true} data={data} />
                  ) : data.type === "bring" ? (
                    <BringWidget live={!dashboardEdit} data={data} />
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      // Guest Mobile View
      return (
        <div className="responseDashboard">
          <div className="widgetHolder grid">
            {currentEvent?.event_type !== "free" && (
              <div className="widget1">
                <TicketWidget />
              </div>
            )}
            {currentEvent?.event_type !== "free" && (
              <div className="widget1">
                <InvoiceWidget />
              </div>
            )}
            {/* <div className="widget2">
              <FundingProgressWidget />
            </div> */}
            {(refresh2 || !refresh2) &&
              currentDashboard.map((data) => {
                let classString = data.component_size === "small" ? "widget2" : "widget2 widget2y";
                if (data.type === "photo") {
                  classString += " widgetPadding0";
                }
                return (
                  <div className={classString} key={data.type}>
                    {data.type === "poll" ? (
                      <PollWidget live={true} data={data} />
                    ) : data.type === "prompt" ? (
                      <PromptWidget live={true} data={data} />
                    ) : data.type === "photo" ? (
                      <PhotoWidget live={true} data={data} />
                    ) : data.type === "bring" ? (
                      <BringWidget live={!dashboardEdit} data={data} />
                    ) : null}
                  </div>
                );
              })}
          </div>
        </div>
      );
    }
  }

  // Owner Mobile View
  if (!isDesktop) {
    return (
      <div className="responseDashboard">
        <div className="widgetHolder grid">
          <div className="widget1 widgetBlack">
            <ScanWidget event={currentEvent} />
          </div>
          <div className="widget1">
            <LiveDashboardToggleWidget />
          </div>
          {/* <div className="widget2">
            <FundingProgressWidget />
          </div> */}
          {(refresh2 || !refresh2) &&
            currentDashboard.map((data) => {
              let classString = data.component_size === "small" ? "widget2" : "widget2 widget2y";
              if (data.type === "photo") {
                classString += " widgetPadding0";
              }
              return (
                <div className={classString} key={data.type}>
                  {data.type === "poll" ? (
                    <PollWidget live={true} data={data} />
                  ) : data.type === "prompt" ? (
                    <PromptWidget live={true} data={data} />
                  ) : data.type === "photo" ? (
                    <PhotoWidget live={true} data={data} />
                  ) : data.type === "bring" ? (
                    <BringWidget live={!dashboardEdit} data={data} />
                  ) : null}
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  // Owner Desktop View
  return (
    <div className="responseDashboard">
      <div className="widgetHolder grid">
        {/* <div className="widget2">
          <FundingProgressWidget />
        </div> */}
        {(refresh2 || !refresh2) &&
          dashL.map((data) => {
            let classString = data.component_size === "small" ? "widget2" : "widget2 widget2y";
            if (data.type === "photo" && !dashboardEdit) {
              classString += " widgetPadding0";
            }
            return (
              <DragItem key={data.dashboard_id} id={data.dashboard_id} onMoveItem={moveItem}>
                <GridItem className={classString} key={data?.dashboard_id ?? data.type}>
                  {data.type === "poll" ? (
                    <PollWidget live={!dashboardEdit} data={data} />
                  ) : data.type === "prompt" ? (
                    <PromptWidget live={!dashboardEdit} data={data} />
                  ) : data.type === "photo" ? (
                    <PhotoWidget live={!dashboardEdit} data={data} />
                  ) : data.type === "bring" ? (
                    <BringWidget live={!dashboardEdit} data={data} />
                  ) : null}
                </GridItem>
              </DragItem>
            );
          })}
        {dashboardEdit ? (
          <div className="widget2 widgetGray" key={"New"}>
            <NewWidget nextIndex={nextIndex} />
          </div>
        ) : null}
      </div>
      <div className="widgetHolder grid">
        <div className="widget1 widgetBlack">
          <ScanWidget event={currentEvent} />
        </div>
        <div className="widget1">
          <LiveDashboardToggleWidget />
        </div>
        {dashR.map((data) => {
          let classString = data.component_size === "small" ? "widget2" : "widget2 widget2y";
          if (data.type === "photo" && !dashboardEdit) {
            classString += " widgetPadding0";
          }
          return (
            <DragItem key={data.dashboard_id} id={data.dashboard_id} onMoveItem={moveItem}>
              <GridItem className={classString} key={data?.dashboard_id ?? data.type}>
                {data.type === "poll" ? (
                  <PollWidget live={!dashboardEdit} data={data} />
                ) : data.type === "prompt" ? (
                  <PromptWidget live={!dashboardEdit} data={data} />
                ) : data.type === "photo" ? (
                  <PhotoWidget live={!dashboardEdit} data={data} />
                ) : data.type === "bring" ? (
                  <BringWidget live={!dashboardEdit} data={data} />
                ) : null}
              </GridItem>
            </DragItem>
          );
        })}
        {dashboardEdit ? (
          <div className="widget2 widgetGray" key={"New"}>
            <NewWidget nextIndex={nextIndex} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ResponseManagement;
